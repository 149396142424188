<template>
  <v-container>
    <!-- <div class="page-header">Welcome home...</div> -->
    <!-- <v-divider class="pa-2 mb-2"></v-divider> -->
    <v-row>
      <v-flex md4 style="padding: 10px">
        <v-card
          class="mx-auto"
          color="primary lighten-1"
          dark
          img="https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dmFjYXRpb24lMjBob21lfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
        >
          <v-card-title style="justify-content: right">
            <v-icon large right color="primary lighten-1"> loyalty </v-icon>
            <!-- <span class="text-h6 font-weight-light">12 rentals in progress</span> -->
          </v-card-title>

          <!-- <v-card-text class="text-h5 font-weight-bold">
            <v-row
              align="center"
              justify="end"
              style="color: white; background-color: rgba(0, 0, 0, 0.3)"
            >
              {{ total_active_properties }} destination
            </v-row>
          </v-card-text> -->

          <v-card-actions>
            <v-list-item class="grow">
              <v-row align="center" justify="end">
                <span class="subheading mr-2">
                  <v-icon> mdi-home </v-icon>
                  {{ active_properties_by_type.HOUSE }}
                </span>
                <!-- <v-spacer /> -->
                <span class="subheading mr-2">
                  <v-icon> apartment </v-icon>
                  {{ active_properties_by_type.APARTMENT }}
                </span>
                <!-- <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> cabin </v-icon>
                  {{ active_properties_by_type.CAMPING }}
                </span>
                <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> garage </v-icon>
                  {{ active_properties_by_type.GARAGE }}
                </span>
                <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> warehouse </v-icon>
                  {{ active_properties_by_type.WAREHOUSE }}
                </span>
                <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> nightlife </v-icon>
                  {{ active_properties_by_type.EVENT }}
                </span>
                <v-spacer /> -->
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 10px">
        <v-card
          class="mx-auto"
          color="primary lighten-1"
          dark
          img="https://media.istockphoto.com/photos/wooden-pendant-of-a-house-and-key-background-of-sea-fir-tree-and-picture-id1304532341?b=1&k=20&m=1304532341&s=170667a&w=0&h=KpUDK5Qz95nUrt9yw-4GlW7iO6Tu4k7dA11Gtcf3IPs="
        >
          <v-card-title style="justify-content: right">
            <v-icon large left color="primary"> real_estate_agent </v-icon>
            <!-- <span class="text-h5 font-weight-bold">8</span> -->
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            <v-row
              align="center"
              justify="end"
              style="color: white; background-color: rgba(0, 0, 0, 0.3)"
            >
              {{ total_active_events }} rentals in progress
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow" style="justify-content: right">
              <!-- <v-btn color="orange" text> Share </v-btn> -->
              <v-btn color="orange" text> Check them </v-btn>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 10px">
        <v-card
          class="mx-auto"
          color="primary lighten-1"
          dark
          img="https://t3.ftcdn.net/jpg/03/29/37/64/360_F_329376481_iNs6iGtCsqiz4B5EPj4BtrylC4JhUOj3.jpg"
        >
          <v-card-title style="justify-content: right">
            <v-icon large left color="primary"> event_available </v-icon>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            <v-row
              align="center"
              justify="center"
              style="color: white; background-color: rgba(0, 0, 0, 0.3)"
            >
              {{ total_guests_in_action }} guests in action
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow" style="justify-content: center">
              <v-btn color="orange" text>Chat them </v-btn>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import PropertyDashService from "@/services/propertyDashService";

export default {
  data: () => ({
    total_guests_in_action: 0,
    total_active_events: 0,
    total_active_properties: 0,
    active_properties_by_type: {
      HOUSE: 0,
      APARTMENT: 0,
      CAMPING: 0,
      WAREHOUSE: 0,
      GARAGE: 0,
      EVENT: 0,
    },
  }),
  created() {
    // console.log('----create dash');
    this.getDataInfo();
  },
  methods: {
    async getDataInfo() {
      const data = await PropertyDashService.getDashHeaderData();
      this.$store.dispatch("subtractLoading");

      let info = data.data;
      // console.log(info.guests_in_action[0].count);
      this.total_guests_in_action = info.guests_in_action[0].count;

      info.active_events.forEach((item) => {
        this.total_active_events += item.count;
      });

      info.active_properties.forEach((item) => {
        this.active_properties_by_type[item.property_type] = item.count;
        this.total_active_properties += item.count;
      });

      // console.log(this.active_properties_by_type);
    },
  },
};
</script>

<style>
</style>