import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import SignUpValidation from "../views/SignUpValidation";
import ForgotPassword from "../views/ForgotPassword.vue";
import PatientWrapper from "../views/PatientWrapper.vue";
import HomeWrapper from "../views/HomeWrapper.vue";
import EventsWrapper from "../views/EventsWrapper.vue";
import PropertiesWrapper from "../views/PropertiesWrapper.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import ScheduleDetailsWrapper from "../views/ScheduleDetailsWrapper.vue";
import ManagementWrapper from "../views/ManagementWrapper.vue";
import NotFound from "../components/shared/NotFound.vue";
import store from "../store/index";
import CompanySelection from "../views/CompanySelection.vue";
import Support from "../views/Support.vue";
import TestChat from "../components/chat/TestChat.vue";
import AppDownloadInstructions from "../views/AppDownloadInstructions.vue";
import { parseJwt } from "../utils/JWTDecoder";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/signup",
      name: "signup",
      component: SignUp,
    },
    {
      path: "/login/company-selection",
      name: "company-selection",
      component: CompanySelection,
    },
    {
      path: "/support",
      name: "support",
      component: Support,
    },
    {
      path: "/chat",
      name: "chat",
      component: TestChat,
    },
    {
      path: "/signup/validation",
      name: "signup-validation",
      component: SignUpValidation,
    },
    {
      path: "/forgot-password",
      name: "forgotpassword",
      component: ForgotPassword,
    },
    {
      path: "/patient-list",
      name: "patient-list",
      component: PatientWrapper,
    },
    {
      path: "/property",
      name: "property",
      component: PropertiesWrapper,
    },
    {
      path: "/rental",
      name: "rental",
      component: EventsWrapper,
    },
    {
      path: "/",
      name: "home",
      component: HomeWrapper,
    },
    {
      path: "/management",
      name: "management",
      component: ManagementWrapper,
    },
    {
      path: "/schedule-details/:id",
      name: "schedule-details",
      component: ScheduleDetailsWrapper,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/app-download-instructions",
      name: "app-download-instructions",
      component: AppDownloadInstructions,
    },
    {
      path: "*",
      name: "404",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("tryAutoLogin");
  const userToken = store.getters.userToken;
  var loggedin = true;
  if (!userToken || userToken && (parseJwt(userToken).exp < Date.now()/1000)) {
      console.log('token expired');
      // deleteTokenFromLocalStorage();
      loggedin = false;
  }
  
  var isMainAdmin = loggedin ? (parseJwt(userToken).main_admin && parseJwt(userToken).license_type != "FREE_ACCOUNT"): false
  
  if (userToken && loggedin && to.path === "/management" && !isMainAdmin) {
    next("/login");
  } 
  if (
    !loggedin &&
    to.path !== "/support" &&
    to.path !== "/privacy-policy" &&
    to.path !== "/login" &&
    to.path !== "/forgot-password" &&
    to.path !== "/signup" &&
    to.path !== "/signup/validation" &&
    to.path !== "/login/company-selection" &&
    to.path !== "/app-download-instructions" 
  ) {
    next("/login");
  } else {
    next();
  }
});
export default router;
